<template>
  <footer class="footer">
    <b-container>
      <aside class="footer-aside">
<!--        <div class="footer-aside__img">-->
<!--          <img-->
<!--            :src="require('../../assets/img/cr-shield-inverted.png')"-->
<!--          >-->
<!--        </div>-->
        <div class="footer-aside__text">
          <p>
            MF Solvency S.r.l.
          </p>
          <p>
            Piazzale Luigi Cadorna 13, 20123 Milano (MI)
          </p>
          <p>
            PIVA: IT11056420968
          </p>
        </div>
      </aside>
      <ul class="footer-nav">
        <li class="footer-nav-item">
          <a
            class="footer-nav-link"
            href="https://mfsolvency.it/termini-e-condizioni/"
            target="_blank"
          >
            Termini &amp; Condizioni
          </a>
        </li>
        <li class="footer-nav-item">
          <a
            class="footer-nav-link"
            href="https://mfsolvency.it/informativa-privacy/"
            target="_blank"
          >
            Privacy Policy
          </a>
        </li>
      </ul>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
