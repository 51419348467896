<template>
  <div>
<!--    <heading2-->
<!--      :heading="balanceLabel"-->
<!--      class="mb-0"-->
<!--    />-->
    <b-card v-if="!noDataPresent">
      <div>
        <p class="form-label">
          <strong>{{balanceTypeLabel}}</strong> {{balanceTypeData}}
        </p>
      </div>
      <div>
        <b-form-row>
          <b-col class="d-flex mb-3" cols="12">
            <card-accent
              value-card
              card-value-fixed
              :title="incomeDataTitle"
              :cardValue="incomeDataValue"
              :cardPercentage="incomeDataPercentage"
            />
          </b-col>
          <b-col class="d-flex mb-3" cols="12" sm="6" xl="3">
            <card-accent
              value-card
              card-value-fixed
              :title="purchasesDataTitle"
              :cardValue="purchasesDataValue"
              :cardPercentage="purchasesDataPercentage"
            />
          </b-col>
          <b-col class="d-flex mb-3" cols="12" sm="6" xl="3">
            <card-accent
              value-card
              card-value-fixed
              :title="molDataTitle"
              :cardValue="molDataValue"
              :cardPercentage="molDataPercentage"
            />
          </b-col>
          <b-col class="d-flex mb-3" cols="12" sm="6" xl="3">
            <card-accent
              value-card
              card-value-fixed
              :title="profitLossDataTitle"
              :cardValue="profitLossDataValue"
              :cardPercentage="profitLossDataPercentage"
            />
          </b-col>
          <b-col class="d-flex mb-3" cols="12" sm="6" xl="3">
            <card-accent
              value-card
              card-value-fixed
              :title="assetsDataTitle"
              :cardValue="assetsDataValue"
              :cardPercentage="assetsDataPercentage"
            />
          </b-col>
          <!--            <b-col class="d-flex">-->
          <!--              <card-accent-->
          <!--                value-card-->
          <!--                :title="entepriseTitle"-->
          <!--                :cardValue="entepriseValue"-->
          <!--                :cardPercentage="enteprisePercentage"-->
          <!--              />-->
          <!--            </b-col>-->

        </b-form-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { formatDateOnly, formatKEuro } from '@/utils/formatterHelper';
import { isNotEmpty, isPresent } from '@/utils/validators';
// import Heading2 from '@/components/clickCard/Heading2.vue';
import CardAccent from '@/components/clickCard/CardAccent.vue';

export default {
  name: 'BalanceData',
  components: { CardAccent },
  props: {
    data: Object,
  },
  computed: {
    noDataPresent() {
      return isEmpty(this.data);
    },
    balanceLabel() {
      return `Ultimo Bilancio: ${this.balanceLastData}`;
    },
    balanceDepDateLabel() {
      return 'Data di deposito:';
    },
    balanceDepDateData() {
      if (this.getScoringData().depositDate) {
        const a = new Date(this.getScoringData().depositDate);
        return formatDateOnly(a, 'it');
        // const a = this.getScoringData().closeDate * 0.001;
        // return moment(a).format("DD/MM/YYYY");
      }
      return this.notAvailableMsg;
    },
    balanceLastData() {
      if (this.getScoringData()?.lastBalanceDate) {
        const a = new Date(this.getScoringData().lastBalanceDate);
        return formatDateOnly(a, 'it');
        // const a = this.getScoringData().closeDate * 0.001;
        // return moment(a).format("DD/MM/YYYY");
      }
      return this.notAvailableMsg;
    },
    balanceTypeLabel() {
      return 'Tipologia di bilancio:';
    },
    balanceTypeData() {
      if (isPresent(this.getScoringData()?.balanceType)) {
        return this.getScoringData().balanceType;
      }
      return this.notAvailableMsg;
    },
    incomeDataTitle() {
      return 'FATTURATO:';
    },
    incomeDataValue() {
      let value;
      if (isNotEmpty(this.getScoringData().balanceRevenue)) {
        value = formatKEuro(this.getScoringData().balanceRevenue);
      }
      return value || this.notAvailableMsg;
    },
    incomeDataPercentage() {
      if (isNotEmpty(this.getScoringData().balanceRevenueDelta)) {
        const current = this.getScoringData().balanceRevenueDelta;
        if (current) return current.toFixed(3);
      }
      return null;
    },
    purchasesDataTitle() {
      return 'ACQUISTI:';
    },
    purchasesDataValue() {
      let value;
      if (isNotEmpty(this.getScoringData().balancePurchases)) {
        value = formatKEuro(this.getScoringData().balancePurchases);
      }
      return value || this.notAvailableMsg;
    },
    purchasesDataPercentage() {
      if (isNotEmpty(this.getScoringData().balancePurchasesDelta)) {
        const current = this.getScoringData().balancePurchasesDelta;
        if (current) return current.toFixed(3);
      }

      return null;
    },
    molDataTitle() {
      return 'MOL:';
    },
    molDataValue() {
      let value;
      if (isNotEmpty(this.getScoringData().balanceMol)) {
        value = formatKEuro(this.getScoringData().balanceMol);
      }
      return value || this.notAvailableMsg;
    },
    molDataPercentage() {
      if (isNotEmpty(this.getScoringData().balanceMolDelta)) {
        const current = this.getScoringData().balanceMolDelta;
        if (current) return current.toFixed(3);
      }
      return null;
    },
    profitLossDataTitle() {
      return 'UTILE/PERDITA:';
    },
    profitLossDataValue() {
      let value;
      if (isNotEmpty(this.getScoringData().balanceProfitLoss)) {
        value = formatKEuro(this.getScoringData().balanceProfitLoss);
      }
      return value || this.notAvailableMsg;
    },
    profitLossDataPercentage() {
      if (isNotEmpty(this.getScoringData().balanceProfitLossDelta)) {
        const current = this.getScoringData().balanceProfitLossDelta;
        if (current) return current.toFixed(3);
      }
      return null;
    },
    assetsDataTitle() {
      return 'PATRIMONIO:';
    },
    assetsDataValue() {
      let value;
      if (isNotEmpty(this.getScoringData()?.balanceAssets)) {
        value = formatKEuro(this.getScoringData().balanceAssets);
      }
      return value || this.notAvailableMsg;
    },
    assetsDataPercentage() {
      if (isNotEmpty(this.getScoringData().balanceAssetsDelta)) {
        const current = this.getScoringData().balanceAssetsDelta;
        if (current) return current.toFixed(3);
      }
      return null;
    },
    entepriseTitle() {
      return 'ENTERPRISE VALUE:';
    },
    entepriseValue() {
      let value;
      // if (this.getScoringData()?.totalLiability) {
      //   value = this.getScoringData().totalLiability.totalShareholdersEquity.totalShareholdersEquity;
      // }
      return value || this.notAvailableMsg;
    },
    enteprisePercentage() {
      // if (this.getScoringData()?.totalLiability) {
      //   const current = this.getScoringData().totalLiability.totalShareholdersEquity.totalShareholdersEquity;
      //   if (this.balanceDetail(1)?.totalLiability) {
      //     const previous = this.balanceDetail(1).totalLiability.totalShareholdersEquity.totalShareholdersEquity;
      //     if (current && previous) {
      //       const value = (current - previous) / previous;
      //       return value.toFixed(3);
      //     }
      //   }
      // }
      return null;
    },
  },
  methods: {
    getScoringData() {
      return this.data?.scoring?.clickSection || {};
    },
  },
};
</script>

<style scoped>

</style>
