<template>
  <div>
    <b-card v-if="!noDataPresent">
      <div>
        <p class="form-label bold-font">
          Note
        </p>
      </div>
      <div>
        <p class="form-label">
          {{notes}}
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'ClickReportNotes',
  props: {
    data: Object,
    scoringLabel: String,
  },
  computed: {
    noDataPresent() {
      return !isNotEmpty(this.data);
    },
    notes() {
      if (this.noDataPresent) {
        return 'Nessuna nota presente';
      }
      const { hasInvalidState, hasDistancePenalty } = this.data;

      if (hasInvalidState || (hasDistancePenalty && this.scoringLabel === 'D')) {
        return 'Scoring e limite di credito azzerati per mancato deposito dell’ultimo '
          + 'bilancio e/o presenza di procedure e/o società non Attiva';
      }
      if (hasDistancePenalty) {
        return 'Limite di credito azzerato per mancato deposito dell’ultimo bilancio';
      }
      return 'Nessuna nota presente';
    },
  },
};

</script>

<style scoped>
.bold-font {
  font-weight: bold;
}
</style>
